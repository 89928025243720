@import "../../../../styles/colors.scss";

.upload-file {
  width: 150px;

  &--document {
    width: 100%; }

  &__dropzone {
    height: 110px;
    border-radius: 2px;
    border: 1px solid $light-grey;
    position: relative;
    background-color: $white;
    background-size: cover;

    &--disabled {
      height: 110px;
      border-radius: 2px;
      border: 1px solid $light-grey;
      position: relative;
      background-color: #ebebeb;
      background-size: cover; }

    &--document {
      box-sizing: border-box;
      box-shadow: none; } }

  &__control {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;

    &--document {
      padding: 0 75px 0 36px;
      justify-content: flex-start; } }

  &__loader {
    transform: rotate(0);
    animation: loader 1.3s infinite; }

  &__title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--error {
      color: $red; } }

  &__title-icon {
    flex-shrink: 0;
    line-height: 0;

    svg {
      line-height: 0;
      fill: #000; } }

  &__file {
    display: flex;
    align-items: center;

    svg {
      line-height: 0; } }

  &__file-name {
    font-size: 12px;
    font-weight: 200;
    color: $black;
    word-break: break-word;
    margin-top: 5px;

    &--document {
      margin-top: 0;
      margin-left: 15px; } }

  &__error {
    color: $red;
    font-size: 10px;
    margin-top: 10px;
    text-align: center; }

  &__delete {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;

    svg {
      width: 100%;
      height: 100%; }

    &--document {
      width: 30px;
      height: 30px;
      top: 50%;
      transform: translate(0, -50%);
      right: 28px; } } }


@keyframes loader {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

