@import './colors.scss';

$separatorWidth: 100vw;

.form {
  &__title {
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: $red;
    margin-bottom: 30px;
  } 

  &__subtitle {
    font-weight: bold;
    font-size: 20px;
  }

  &__input-label {
    position: absolute;
    right: -70px;
    top: 0;
    transform: translateX(100%);

    &--align-bottom {
      bottom: 0;
      top: auto;
    }
  }

  &__input-title {
    font-weight: 500;
    display: flex;
    justify-content: space-between;

    &--error {
      color: $red
    }
  }

  &__input-title-icon {
    flex-shrink: 0;
    line-height: 0;
    height: 18px;
  }

  &__input {
    position: relative;
    margin-top: 15px;
  }

  &__input-block {
    margin-top: 33px;
  }

  &__input-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;

    & > .form__input-block, & > .form__input {
      width: 305px;
    }
  }

  &__section {
    padding-bottom: 50px;
    position: relative;

    &:not(:first-child) {
      padding-top: 50px;
    }

    &:after {
      content: '';
      display: block;
      height: 27vw;
      width: $separatorWidth;
      position: absolute;
      z-index: -1;
      left: calc((#{$separatorWidth} - 1150px) / 2 * -1);
      bottom: 0;
      background-image: url(../assets/images/form-section-end.svg);
      background-size: 101% auto;
      background-position: bottom left;
      min-width: 1150px;

      @include respond-below(desktop-xs) {
        left: -15px;
      } 
    }

    &--last {
      padding-bottom: 0;
      
      &:after {
        display: none;
      }
    }
  }
}

  // &__separator
  //   height: 1px
  //   background-color: $light-gray

  // &__document
  //   display: flex
  //   align-items: center
  //   margin-top: 15px
  
  // &__document-img
  //   flex-shrink: 0
  //   margin-right: 11px
  //   line-height: 0

  // &__document-link
  //   font-size: 14px
  //   line-height: 22px
  //   color: $purple
  
  // &__document-remove
  //   margin-left: 5px
  //   line-height: 0

  // &__images-row
  //   display: flex
  //   align-items: flex-start
  //   position: relative
  //   flex-wrap: wrap

  //   & > div
  //     margin-right: 10px

  //     &:last-child
  //       margin-right: 0