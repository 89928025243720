@import "../../../../styles/colors";

.crm-list-item {
  display: flex;
  align-items: center;
  height: 70px;
  object-fit: contain;
  background-color: $white;
  padding: 0 30px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px;

  cursor: pointer;

  &__number {
    width: 15px;
    height: 15px;
    margin-right: 15px;
    color: turquoise;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  &__avatar {
    width: 45px;
    height: 45px;
    object-fit: contain;
    border-radius: 50%;
    background-color: $light-grey;
    margin-right: 25px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__name {
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  &__status-indicator {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: $white;
    margin-left: 17px;
  }

  &__status {
    font-size: 14px;
    font-weight: 500;
  }

  &--grey {
    background-color: $light-grey;
  }
  &--yellow {
    background-color: $yellow;
  }
  &--green {
    background-color: $light-turquoise;
  }
  &--red {
    background-color: $red;
  }
  &--blue {
    background-color: $blue;
  }
  &--top100 {
    background-color: #F2FBFA;
  }

  &__rating {
    display: flex;
    align-items: center;
  }

  &__rating-number {
    margin-right: 15px;
    font-weight: 500;
  }

  &__rating-image {
    width: 20px;
    height: 20px;
  }
}
