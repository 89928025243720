@import '../../../styles/colors.scss';

.genre-radio-list {
  display: flex;
  flex-wrap: wrap;

  &__error {
    color: $red;
    font-size: 12px;
    margin-top: 9px;
    line-height: 12px;
    margin-bottom: -21px;
  }
}
.genre-radio-button {
  width: 105px;
  margin-bottom: 6px;
  display: block;
  user-select: none;
  cursor: pointer;

  & > input {
    width: 0;
    height: 0;
    border: none;
    background: transparent;
    outline: none;
    line-height: 0;
    font-size: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    opacity: 0;

    &:checked {
      & + .genre-radio-button__icon {
        background-color: $turquoise;
      }
    }
    &:checked:disabled {
      & + .genre-radio-button__icon {
        background-color: $turquoise;
        opacity: 0.4;
      }
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $light-grey;
    margin: auto;
  }

  &__caption {
    font-size: 12px;
    margin-top: 12px;
    line-height: 22px;
    color: $black;
    font-weight: 200;
    text-align: center;
  }
}
