@import "../../../../styles/colors";

.artist-notifications {
  &__title {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase; }

  &__notifications {
    width: 700px; }

  &__notifications-item {
    border-radius: 10px;
    padding: 20px 35px;
    margin-top: 15px;
    background-color: $pale-blue;

    &:first-child {
      margin-top: 0; }

    &--alert {
      background-color: $red;
      color: $white; }

    &--success {
      background-color: $pale-blue;
      color: $white; } }

  &__notifications-data {
    display: flex;
    align-items: center; }

  &__notifications-date {
    font-size: 14px;
    text-align: right;
    margin-top: 2px; }

  &__notifications-image {
    flex-shrink: 0;
    margin-right: 15px; }

  &__notifications-text {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500; }

  &__rating-button {
    flex-shrink: 0;
    width: 280px; }

  &__no-notifications-text {
    font-size: 14px;
    line-height: 22px; } }
