.icon-with-text {
  display: flex;
  align-items: center;
  
  &__icon {
    margin-right: 20px;
    flex-shrink: 0;
  }

  &__text {
    font-weight: 500;
  }
}