@import "../../../../styles/colors.scss";

.evaluation-project-defense-form {
  background-color: $white;
  border-radius: 2px;
  padding: 62px 45px 52px;
  box-sizing: border-box;

  &__title {
    font-weight: 700;
    font-size: 20px;
    text-align: center;
  }

  &__subtitle {
    margin-top: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  &__input-block {
    margin-top: 40px;
  }

  &__input-title {
    font-size: 12px;
    color: $turquoise;
  }

  &__input-row {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__input-label {
    width: 420px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  &__input {
    width: 80px;
  }

  &__textarea {
    width: 100%;
    height: 100px;
  }

  &__summary {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
  }

  &__summary-title {
    font-weight: 700;
    font-size: 20px;
  }

  &__summary-number {
    font-weight: 700;
    font-size: 20px;
  }

  &__submit {
    width: 305px;
    margin: auto;
    margin-top: 40px;
  }

  &__error {
    color: $red;
    font-size: 12px;
    margin-top: 14px;
    text-align: center;
  }
}