@import '../../../styles/colors.scss';

.textarea-component {
  height: 100%;

  &__textarea-block {
    width: 100%;
    max-height: 100%;
    height: 190px;
    box-sizing: border-box;
    border: 1px solid $light-grey;
    border-radius: 2px;
    outline: none;

    &--error {
      border-color: $red;

      .textarea-component__textarea {
        color: $red;
      }
    }

    &--disabled {
      background-color: $light-grey;
    }
  }

  &__textarea {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    padding: 15px 24px;
    font-size: 16px;
    box-sizing: border-box;
    border: none;
    outline: none;

    &:focus {
      border: none;
      outline: none;
    }

    &::placeholder {
      color: rgba(0,0,0,.15);
    }
  }

  &__error {
    color: $red;
    font-size: 12px;
    margin-top: 9px;
  }
}