@import "../../../styles/colors.scss";
@import "../../../styles/media-queries-mixins.scss";

.winner-crm-header {
  background-image: url(../../../assets/images/crm-header-image.jpg);
  background-size: cover;
  padding-top: 15px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  background-position: bottom;
  box-sizing: border-box;
  height: 31.25vw;
  min-height: 340px;
  flex-shrink: 0;

  @include respond-above(desktop-regular) {
    background-image: url(../../../assets/images/crm-header-image@1.5x.jpg);
    padding-bottom: 150px;
  }

  @include retina {
    background-image: url(../../../assets/images/crm-header-image@1.5x.jpg);
  }

  @include respond-above(desktop-hd) {
    background-image: url(../../../assets/images/crm-header-image@2x.jpg);
  }

  &__content {
    width: 690px;
  }

  &__title {
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 3px;
    color: $white;
  }

  &__text {
    color: $white;
    font-size: 14px;
    line-height: 22px;

    & span {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  &__right-side {
    margin-left: 100px;
    flex-shrink: 0;
  }

  &__label {
    display: flex;
    align-items: center;
  }

  &__label-eye {
    flex-shrink: 0;
    margin-right: 15px;
    line-height: 0;
  }

  &__label-text {
    font-size: 12px;
    color: $white;
  }

  &__save-button {
    width: 220px;
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 18px;
    color: $black;
    font-weight: 700;
    background-color: $white;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
    margin-bottom: 15px;

    svg {
      margin-right: 18px;
      width: 20px;
      height: 20px;
    }
  }

  &__status-label {
    display: flex;
    align-items: center;
    padding: 0px 18px;
    width: 220px;
    height: 45px;
    color: $black;
    font-weight: 700;
    background-color: $white;
    box-sizing: border-box;
    border-radius: 2px;
    margin-bottom: 15px;

    &:before {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-color: $turquoise;
      border-radius: 50%;
      margin-right: 18px;
      flex-shrink: 0;
    }
  }

  &__save-time {
    font-weight: 500;
    color: $white;
    font-size: 12px;
    text-align: right;
    margin-bottom: 10px;
  }

  &__back-btn {
    display: inline-flex;
    align-items: center;
    margin-bottom: 30px;
    cursor: pointer;
  }

  &__back-btn-arrow {
    margin-right: 15px;
    flex-shrink: 0;
    line-height: 0;
  }

  &__back-btn-text {
    color: $white;
    font-size: 14px;
  }
}
