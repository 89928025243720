@import '../../../styles/colors.scss';

.auth-form-layout {
  background-color: $white;
  padding: 44px 67px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  text-align: center;

  &__title {
    color: $black;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 3px;
    text-align: center;
    font-weight: 500;
  }

  &__form {
    width: 100%;
    text-align: left;
    margin-top: 40px;
  }

  &__input {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__button {
    margin-top: 40px;
  }

  &__error {
    color: $red;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: -10px;

    &--static {
      margin-bottom: -20px;
    }
  }

  &__pass-recovery {
    margin-top: 15px;
    color: $turquoise;
    text-align: center;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
}