@import "../../../../styles/colors";

.crm-report-list-item {
  display: flex;
  align-items: center;
  height: 70px;
  object-fit: contain;
  background-color: $white;
  padding: 0 30px;
  margin-bottom: 15px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px;

  &__avatar {
    width: 45px;
    height: 45px;
    object-fit: contain;
    border-radius: 50%;
    background-color: $light-grey;
    margin-right: 25px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__name {
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  &__status {
    font-size: 14px;
  }
  &__status-header {
    text-align: end;
    margin-bottom: 3px;
  }
  &__status-footer {
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
  }
  &__divider {
    padding: 0 10px;
  }
  &--grey {
    color: $grey;
  }
  &--green {
    color: #0db128;
  }
  &--red {
    color: $red;
  }
  &--yellow {
    color: #FF6A3A;
  }
}
