@import './styles/fonts.scss';
@import './styles/layout.scss';
@import './styles/text-styles.scss';
@import './styles/form-styles.scss';
@import './styles/admin-common-styles.scss';

html,body {
  width: 100%;
  min-width: 1060px;
  font-family: 'Geometria';
}

a {
  color: inherit;
}

.app-content-wrapper {
  // overflow: hidden; чтобы работал posotion: sticky в файле ArtistSidebar.style.scss

  & > div {
    min-height: 100vh;
  }
}