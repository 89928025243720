@import '../../../styles/colors.scss';
  
.auth-info {
  background-color: $turquoise;
  padding: 55px 47px;
  box-sizing: border-box;
  color: $white;

  &__title {
    line-height: 50px;
    font-size: 35px;
    text-align: center;
    font-weight: 700;
  }

  &__text {
    font-size: 16px;
    line-height: 35px;
    text-align: center;
    font-weight: 700;
  }

  &__button {
    width: 305px;
    margin-left: auto;
    margin-right: auto;
  }
}