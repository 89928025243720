.dashboard {
  padding-top: 80px;
  position: relative;
  min-height: 100px;
  padding-bottom: 100px;

  &__artists-left {
    margin-top: 20px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  &__evaluated-artists {
    margin-top: 40px;
  }
}