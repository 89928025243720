@import "../../../styles/colors.scss";

.input-in-table-component {
  &__input-block {
    display: flex;
    align-items: center;
    height: 46px;
    box-sizing: border-box;
    border: 1px solid $white;
    font-size: 14px;
    background-color: $white;

    &--error {
      border-bottom: 1px solid $red;

      .input-in-table-component__input {
        background-color: rgba(230, 60, 36, 0.15);
        color: $red;
      }
    }

    &--disabled {
      background-color: $light-grey;
    }
  }

  &__input {
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    padding: 0 15px 0 24px;
    color: $black;
    box-sizing: border-box;

    &::placeholder {
      color: rgba(0, 0, 0, 0.35);
    }

    &:focus {
      background: rgba(33, 206, 180, 0.1);
      border-bottom: 2px solid $turquoise;
    }
  }

  &__error {
    color: $red;
    font-size: 12px;
    margin-top: 9px;
    line-height: 12px;
    margin-bottom: -21px;

    &--static {
      margin-bottom: 0;
    }
  }
}
