@import "../../../../styles/colors";
@import "../../../../styles/admin-variables.scss";

.admin-layout {
  &__body {
    display: flex;
    align-items: flex-start;
  }

  &__content {
    margin-left: $admin-sidebar-width;
    width: 100%;
  }
}
