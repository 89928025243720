@import "../../../../styles/colors";

.circle-button {
  width: 50px;
  height: 50px;
  background-color: $turquoise;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  svg path {
    fill: $white;
  }

  &:hover {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    background-color: rgba(33, 206, 180, 0.65);
  }
}
