@import "../../../../styles/colors.scss";

.evaluation-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  border-radius: 10px;
  padding: 132px 45px 32px;
  box-sizing: border-box;

  &__title {
    color: $red;
    font-weight: 700;
    font-size: 50px;
    text-align: center;
    margin-bottom: 24px;
  }

  &__subtitle {
    font-size: 20px;
    margin-bottom: 56px;
  }

  &__submit {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  &__cancel {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
