@import './colors';
  
.page-title {
  font-size: 35px;
  line-height: 50px;
  font-weight: 700;
  color: $white;
  text-align: center;

  &--align-left {
    text-align: left;
  }
    
  &--align-right {
    text-align: right;
  }

  &--black {
    color: $black;
  }
}

.page-subtitle {
  font-weight: 700;
  font-size: 26px;
  color: $black;
  text-align: center;

  &--align-left {
    text-align: left;
  } 
  
  &--align-right {
    text-align: right;
  }
}

.page-text {
  font-size: 20px;
  line-height: 29px;
  color: $black;

  &--bold {
    font-weight: 700;
  }

  &--align-left {
    text-align: left;
  }

  &--align-right {
    text-align: right;
  }

  &--align-center {
    text-align: center;
  }

  &--s {
    font-size: 16px;
  }

  &--l {
    font-size: 24px;
  }
}

.text-paragraph {
  margin: 30px 0;
}

.uppercase {
  text-transform: uppercase;
}