@import "../../../../styles/colors";

.admin-approve-sidebar {
  width: 460px;

  &__comments {
    max-height: 350px;
    overflow-y: scroll;
  }

  &__comments-section {
    margin-top: 50px;
  }

  &__comments-title {
    line-height: 35px;
    font-weight: 500;
    font-size: 16px;
  }

  &__comments-item {
    padding-right: 20px;
    margin-top: 26px;
  }

  &__comments-item-text {
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  &__comments-item-date {
    margin-top: 12px;
    font-size: 14px;
    color: $black;
    opacity: 0.3;
  }

  &__comments-item-header {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 14px;

      &--admin {
        svg rect {
          fill: $red;
        }
      }
    }
    .name {
      font-weight: bold;
      font-size: 16px;
    }
  }
}
