@import "../../../../styles/colors.scss";

.artist-form {
  position: relative;

  .content-container {
    width: 1180px;

    &__icon-links {
      margin-left: 690px;
      display: flex;
      flex-direction: row;
    }
  }

  &--admin {
    .form__input-label {
      display: none;
    }
  }

  &--comments {
    .form__input-label {
      display: none;
    }
  }

  &__form {
    width: 630px;
  }

  &__submit-area {
    margin-top: 40px;
  }

  &__agree-checkbox {
    margin-top: 30px;
  }

  &__submit-button {
    margin-top: 50px;
  }

  &__details {
    font-size: 12px;
    line-height: 20px;
    font-weight: 200;
    color: $black;
    margin-left: 50px;

    .hidden {
      display: none;
    }

    &-title {
      display: flex;
      align-items: center;
      font-weight: 500;
      color: $red;
      margin: 4px 0;
      cursor: pointer;

      span {
        margin-right: 4px;
      }
    }

    &--open {
      svg {
        transform: rotate(180deg);
      }

      .hidden {
        display: block;
      }
    }
  }
}

.meta-note-paragraph {
  font-size: 12px;
  line-height: 20px;

  margin: 40px 0 0;
}
