@import "../../../../styles/colors";
@import "../../../../styles/admin-variables.scss";

.nav-sidebar {
  width: $admin-sidebar-width;
  height: 100%;
  background-color: $turquoise;
  color: $white;
  padding: 0;
  position: fixed;
  left: 0;
  z-index: 4;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  nav {
    flex: 1;
  }

  a {
    text-decoration: none;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 50px;
    padding-left: 18px;
    box-sizing: border-box;
    margin-bottom: 10px;
    opacity: 0.5;
    cursor: pointer;

    &--decorative {
      margin-left: auto;
      position: relative;
      left: 21px;
    }

    &:hover {
      opacity: 1;
    }

    &--active {
      opacity: 1;
      border-left: 2px solid rgba(255, 255, 255, 0.4);
    }

    &-icon {
      svg path {
        fill: $white;
      }
    }
  }

  &__item-title {
    font-weight: 500;
    font-size: 14px;
    color: $white;
    padding-left: 20px;
  }

  &__item-icon {
    text-align: center;
    line-height: 0;
    width: 20px;
  }
}
