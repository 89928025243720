@import './media-queries-mixins.scss';

.content-container {
  margin: auto;
  width: 1060px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}

.row {
  display: flex;

  &--centered {
    justify-content: center;
  }

  &--space-between {
    justify-content: space-between;
  }
  
  &--space-around {
    justify-content: space-around;
  }

  &--end {
    justify-content: flex-end;
  }
    
  &--space-evenly {
    justify-content: space-evenly;
  }
    
  &--align-center {
    align-items: center;
  }

  &--align-bottom {
    align-items: flex-end;
  }

  &--wrap {
    flex-wrap: wrap;
  }
}
  
.margin {
  &--auto {
    margin: auto;
  }
  
  &--top-0 {
    margin-top: 0;
  }
    
  &--top-xxs {
    margin-top: 10px;
  }
    
  &--top-xs {
    margin-top: 20px;
  }
    
  &--top-s {
    margin-top: 30px;
  } 

  &--top-m {
    margin-top: 40px;
  } 

  &--top-l {
    margin-top: 50px;
  } 

  &--top-xl {
    margin-top: 60px;
  }    

  &--top-xxl {
    margin-top: 70px;
  }

  &--bottom-0 {
    margin-bottom: 0;
  }

  &--bottom-xxs {
    margin-bottom: 10px;
  }

  &--bottom-xs {
    margin-bottom: 20px;
  }

  &--bottom-s {
    margin-bottom: 30px;
  }

  &--bottom-m {
    margin-bottom: 40px;
  }

  &--bottom-l {
    margin-bottom: 50px;
  }

  &--bottom-xl {
    margin-bottom: 60px;
  }

  &--bottom-xxl {
    margin-bottom: 70px;
  }
}