@import "../../../../styles/colors.scss";

.artist-rating {
  position: relative;

  &__title {
    font-weight: 700;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
  }

  &__rating {
    width: 150px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 17px;
    border: 1px solid $light-grey;
    border-radius: 2px;
    margin-bottom: 25px;
  }

  &__rating-quantity {
    font-weight: 700;
    font-size: 35px;
    margin-right: 12px;
  }

  &__rating-icon {
    width: 30px;
    height: 30px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__subrating-title {
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: 35px;
  }
  &__subrating-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__subrating {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__subrating-block {
    width: 150px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 17px;
    border: 1px solid $light-grey;
    border-radius: 2px;
  }
  &__subrating-description {
    max-width: 178px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }

  &__comments {
    margin-top: 60px;
  }

  &__comments-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
  }

  &__comments-items {
    margin-top: 25px;
  }
}
