@import '../../../../styles/colors.scss';

.evaluation-info {
  position: relative;

  .content-container {
    width: 660px;
  }

  &__header {
    background-color: $white;
    height: 54.17vw;
    min-height: 340px;
    // max-height: 690px;
    flex-shrink: 0;
    position: relative;
    z-index: 3;

    margin-bottom: 60px;
  }

  &__header-image {
    // height: 100%;
    // width: 100%;
    // clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 91% 90%, 0% 70%);
    // background-size: cover;
    // background-position: center;
    // z-index: 3;
    // position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 60px;
    // max-width: 1440px;
    // max-height: 704px;

    svg {
      z-index: 10;
    }

  }

  &__image-container {
    z-index: 10;
    width: 44vw;
    height: 44vw;
    position: absolute;
    top: 2%;
    clip-path: polygon(16% 0, 100% 0, 100% 75%, 81% 100%, 0 91%, 0% 30%);
    background-color: $white;
    border: 20px solid $white;

    img {
      width: calc(100% - 10px); //for border poligon
      height: calc(100% - 10px);
      object-fit: cover;
      clip-path: polygon(16% 0, 100% 0, 100% 75%, 81% 100%, 0 91%, 0% 30%);
    }
  }

  &__header-substrate {
    position: absolute;
    bottom: 0;
    left: 0;
    // top: 8%;
    // top: 37%;
    z-index: 2;
    background-color: $turquoise;
    height: 100%;
    width: 100%;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 76%);
  }

  &__header-line {
    background-color: $red;
    height: 170px;
    width: 20px;
    transform: skew(-25deg, 0);
    position: absolute;
    bottom: 10%;
    left: 8%;

    &--top {
      z-index: 4;
    }

    &--bottom {
      z-index: 1;
      left: 11.5%;
      bottom: 5%;
    }
  }

  &__info-section {
    background-color: $white;
    clip-path: polygon(0 0, 100% 0, 100% 87%, 91% 96.5%, 0 100%);
    position: relative;
    z-index: 2;
    padding-bottom: 220px;
  }

  &__city {
    margin-top: 26px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
  }

  &__country {
    margin-top: 26px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
  }

  &__genre {
    color: $red;
    margin-top: 26px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
  }

  &__description {
    margin-top: 54px;
  }

  &__block-title {
    font-size: 16px;
    font-weight: 700;
    color: $black;
  }

  &__description-text {
    margin-top: 25px;
    font-size: 16px;
    color: $black;
  }

  &__social-links-wrapper {
    margin-top: 40px;
  }

  &__social-links {
    display: flex;
    align-items: center;
    margin-top: 25px;
  }
  &__music-links {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 25px;
  }

  &__music-links-item {
    width: 100%;
    line-height: 24px;
    padding: 15px 24px;
    border: 1px solid $light-grey;
    border-radius: 2px;
    margin-top: 15px;
    box-sizing: border-box;

    & a {
      color: $red;
      text-decoration: none;
    }
  }

  &__social-links-item {
    margin-right: 24px;
    line-height: 0;

    & a {
      & svg {
        max-width: 30px;
        max-height: 30px;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__section {
    margin-top: 54px;
  }

  &__section-title {
    color: $red;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1.25px;
  }

  &__info-block-wrapper {
    margin-top: 30px;
  }

  &__info-block {
    line-height: 24px;
    padding: 15px 24px;
    border: 1px solid $light-grey;
    border-radius: 2px;
    margin-top: 15px;
    box-sizing: border-box;
  }

  &__info-block-row {
    display: flex;
    justify-content: space-between;

    & > .evaluation-info__info-block-wrapper {
      width: 305px;
    }
  }

  &__document-block {
    padding: 45px 36px;
    border: 1px solid $light-grey;
    border-radius: 2px;
    margin-top: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  &__document-block-img {
    margin-right: 17px;
    line-height: 0;
  }

  &__budget-block {
    padding: 25px 36px;
    cursor: pointer;
    border: 1px solid $light-grey;
    border-radius: 2px;
    margin-top: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }

  &__document-block-preview {
    font-size: 14px;
    font-weight: 400;
    word-break: break-word;
    margin-left: 15px;

    & span {
      color: red;
    }
  }
}
