@import "./admin-variables.scss";

.react-tabs {
  &__tab-list {
    &--white {
      .react-tabs__tab {
        color: white;
      }
    }
  }

  .react-tabs__tab {
    border: none;
    position: initial;
    padding: 0;
    font-family: 'Geometria';
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    color: $light-grey;
    margin-right: 60px;

    &:focus {
      box-shadow: none;
      border: none;

      &:after {
        position: initial;
        height: 0px;
      }
    }

    &-list {
      border: none;
      margin: 0;
    }

    &::after {
      display: none;
    }

    &--selected {
      border: none;
      border-radius: 0;
      opacity: 1;
      background: transparent;
      color: $black;

      &::after {
        display: block;
        content: '';
        width: 54px;
        height: 4px;
        background-color: $red;
        margin-top: 5px;
      }
    }
  }
}


.admin-layout-content {
  min-width: 1000px;

  &__header {
    padding: 60px 70px 20px;
    position: fixed;
    background-color: $white;
    z-index: 2;
    box-sizing: border-box;
    width: calc(100% - #{$admin-sidebar-width});
    min-width: 1000px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  &__title {
    margin-bottom: 23px;
    text-align: left;
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    max-width: 1104px;
  }

  &__body {
    padding: 300px 70px 50px 70px;
    position: relative;
    z-index: 1;

    &--padding-top-s {
      padding-top: 110px
    }

    &--padding-top-0 {
      padding-top: 0;
    }
  }

  &__counter {
    display: flex;
    content: '';
    width: 42px;
    height: 14px;
    border-radius: 2px;
    margin-bottom: 5px;
    background-color: $red;
    font-size: 8px;
    color: $white;
    align-items: center;
    justify-content: center;
  }

  &__loading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: calc(50% + #{$admin-sidebar-width/2});
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  &__add {
    display: flex;
    align-items: center;
  }
    
  &__add-title {
    color: $black;
    font-size: 20px;
    font-weight: 500;
    margin: 0 25px 0 0;
  }

  &__empty {
    font-size: 16px;
    letter-spacing: 1px;
    margin-top: 10px;
  }
}