@import "../../../styles/colors.scss";

.embed-content {
  &__vk {
    min-height: 403px;

    :first-child {
      display: block;
    }
    iframe {
      display: none;
    }
  }

  &__error {
    color: $red;
    font-size: 15px;
    margin-top: 9px;
    line-height: 12px;
    margin-bottom: -21px;
  }
}
