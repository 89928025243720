@import "../../../styles/colors.scss";

.search-component {
  display: flex;
  flex: 1;
  max-width: 500px;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  background-color: $white;
  padding: 0 21px 0 24px;

  &__input {
    flex-grow: 1;
    outline: none;
    border: none;
    height: 100%;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    letter-spacing: 1px;
    padding: 0 12px;
    background: none;

    &::placeholder {
      opacity: 0.5;
      font-size: 14px;
      font-weight: 300;
      color: $black;
    }
  }

  &__icon-clear {
    cursor: pointer;
  }
}