@import '../../../../../styles/colors.scss';


.artist-form-fields {
  &__member-title {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__remove {
    flex-shrink: 0;
    margin-left: 15px;
    width: 30px;
    height: 30px;
    line-height: 0;
    cursor: pointer;

    svg {
      line-height: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__popup {
    height: 110px;
    border-radius: 2px;
    border: 1px solid #ebebeb;
    position: relative;
    background-color: #ffffff;
    background-size: cover;
    width: 150px;
    cursor: pointer;

    &--disabled {
      height: 110px;
      border-radius: 2px;
      border: 1px solid #ebebeb;
      position: relative;
      background-color: #ebebeb;
      background-size: cover;
      width: 150px;
      cursor: pointer;
    }

    &--document {
      width: 100%;
      padding: 0 75px 0 36px;
      box-sizing: border-box;
      overflow: hidden;
    }
  }

  &__control {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    box-sizing: border-box;
    overflow: hidden;

    & svg path {
      stroke: #ffffff;
    }
  }

  &__file {
    display: flex;
    height: 100%;
    align-items: center;
  }

  &__title {
    font-size: 12px;
    font-weight: 200;
    word-break: break-word;
    margin-left: 15px;
    span {
      color: red;
    }
  }
}

.meta-note {
  position: absolute;
  top: 4px;
  right: 4px;

  color: $grey;

  font-size: 16px;
  line-height: 24px;

  
  text-decoration: none;

  padding: 0;
  border: none;
  font-family: inherit;
  
  cursor: pointer;

  background-color: transparent;
}
