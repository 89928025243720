@import '../../../styles/colors';
  
.auth-layout {
  width: 880px;
  // width: 440px;
  border-radius: 10px;
  overflow: hidden;
  color: $white;
  display: flex;
  margin: auto;
  margin-top: 78px;

  &--no-register {
    justify-content: center;

    & > div {
      border-radius: 10px
    }
  }

  & > div {
    min-height: 530px;
    width: 50%;
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}