@import "../../../styles/colors.scss";
@import "../../../styles/admin-variables.scss";

.header {
  background-color: $white;
  height: $header-height;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 5;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;

  &__logo {
    background-image: url(../../../assets/icons/logo.svg);
    width: 150px;
    height: 40px;
    cursor: pointer;
  }
  &__back-btn {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }

  &__back-btn-arrow {
    margin-right: 15px;
    flex-shrink: 0;
    line-height: 0;
    svg path {
      fill: $turquoise;
    }
  }

  &__back-btn-text {
    color: $turquoise;
    font-size: 16px;
  }
}
