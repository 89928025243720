@import "../../../../../styles/colors";
  
.comment-input {
  &__input {
    border-radius: 2px;
    background-color: $pale-blue;
    padding: 17px 28px;
    font-size: 16px;
    line-height: 24px;
    max-width: 100%;
    min-width: 100%;
    max-height: 150px;
    min-height: 150px;
    border: none;
    outline: none;
    box-sizing: border-box;

    &::placeholder {
      color: rgba(0,0,0,.2);
    }
  }
}