.winner-crm-layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__body {
    padding-top: 55px;
    padding-bottom: 150px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
  }
}