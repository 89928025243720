.finalist-form {
  position: relative;

  &__form {
    width: 630px;
  }

  .form__input-label {
    display: none
  }

  &__moderate-block {
    width: 305px;
    margin-top: 90px;
  }

  &__moderate-block-button {
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
}