@import "../../../../styles/colors.scss";

.winner-form {
  position: relative;

  .content-container {
    width: 1180px;
  }

  &--admin {
    .form__input-label {
      display: none;
    }
  }

  &--comments {
    .form__input-label {
      display: none;
    }
  }

  &__form {
    width: 630px;
  }

  &__status {
    font-weight: 500;
    font-size: 20px;
    width: 630px;

    &--grey {
      color: $grey;
    }
    &--green {
      color: #0db128;
    }
    &--yellow {
      color: #ff6a3a;
    }
    &--red {
      color: $red;
    }
  }

  &__submit-area {
    margin-top: 40px;
  }

  &__agree-checkbox {
    margin-top: 30px;
  }

  &__submit-button {
    margin-top: 50px;
  }

  &__details {
    font-size: 12px;
    line-height: 20px;
    font-weight: 200;
    color: $black;
    margin-left: 50px;

    .hidden {
      display: none;
    }

    &-title {
      display: flex;
      align-items: center;
      font-weight: 500;
      color: $red;
      margin: 4px 0;
      cursor: pointer;

      span {
        margin-right: 4px;
      }
    }

    &--open {
      svg {
        transform: rotate(180deg);
      }

      .hidden {
        display: block;
      }
    }
  }

  .react-tabs {
    ul {
      padding: 0;
    }
    .react-tabs__tab {
      width: 50%;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      color: $black;
      opacity: 0.9;
      cursor: pointer;
      margin: 0;

      &--selected {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: $white;
        opacity: 1;
        background-color: $turquoise;
        border-radius: 27px;
        box-shadow: -2px 0px 4px rgba(33, 206, 180, 0.21);

        &::after {
          display: none;
        }
      }
    }
  }

  .tab-list {
    display: flex;
    align-items: center;
    width: 460px;
    height: 54px;
    box-shadow: 0px 4px 12px rgba(185, 185, 185, 0.25);
    border-radius: 27px;
  }
}
