@import '../../../styles/colors.scss';

.input-component {
  &__input-block {
    display: flex;
    align-items: center;
    height: 54px;
    box-sizing: border-box;
    border: 1px solid $light-grey;
    font-size: 16px;
    border-radius: 2px;
    background-color: $white;

    &--error {
      border-color: $red;

      .input-component__input {
        color: $red;
      }
    }

    &--centered {
      .input-component__input {
        text-align: center;
        padding: 0 15px;

        &::placeholder {
          text-align: center;
        } 
      }
    }

    &--with-icon {
      padding-left: 20px;
  
      .input-component__input {
        padding-left: 7px;
      }
    }

    &--disabled {
      background-color: $light-grey;
    }
  }

  &__input {
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    background: transparent;
    width: 100%;
    height: 100%;
    padding: 0 15px 0 24px;
    color: $black;
    box-sizing: border-box;

    &::placeholder {
      color: rgba(0,0,0,.15);
    }
  }

  &__error {
    color: $red;
    font-size: 12px;
    margin-top: 9px;
    line-height: 12px;
    margin-bottom: -21px;

    &--static {
      margin-bottom: 0;
    }
  }

  &__icon {
    margin-right: 10px;
    flex-shrink: 0;
    line-height: 0;

    svg {
      width: 30px;
      height: 30px;
    }
  }
}