@import '../../styles/media-queries-mixins.scss';
@import '../../styles/colors.scss';

.evaluation-page {  
  &__separator {
    clip-path: polygon(0 0, 100% 0, 100% 39.2%, 90.8% 84%, 0 100%);
    height: 700px;
    margin-top: -675px;
    background-color: $yellow;
    position: relative;
    z-index: 1;
  }

  &__evaluation-form {
    padding-top: 480px;
    margin-top: -440px;
    padding-bottom: 220px;
    background-color: $turquoise;
  }
}