@import '../../styles/colors.scss';
@import '../../styles/media-queries-mixins.scss';

.auth-page {
  min-height: 100%;

  &__content {
    padding-top: 90px;
    padding-bottom: 90px;
    background: linear-gradient(0deg, rgba(51, 51, 51, 0.7), rgba(51, 51, 51, 0.7)), url(../../assets/images/auth-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
    box-sizing: border-box;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
    margin-top: 30px;
    color: $white;
  }
}

@include respond-above(desktop-regular) {
  .auth-page {
    background-image: url('../../assets/images/auth-bg@1.5x.jpg');
  }
}

@include respond-above(desktop-hd) {
  .auth-page {
    background-image: url('../../assets/images/auth-bg@2x.jpg');
  }
}