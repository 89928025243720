@import "../../../../styles/colors";

.artist-sidebar {
  width: 460px;

  &__sticky {
    position: sticky;
    background-color: #ffffff;
    top: 30px;
  }
  &--tab-hints {
    display: none;
  }
  &__comments {
    max-height: 300px;
    overflow-y: scroll;
  }

  &__comments-section {
    margin-top: 50px;
  }

  &__comments-title {
    line-height: 35px;
    font-weight: 500;
    font-size: 16px;
  }

  &__comments-item {
    padding-right: 20px;
    margin-top: 26px;
  }

  &__comments-item-text {
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }

  &__comments-item-date {
    margin-top: 12px;
    font-size: 14px;
    color: $black;
    opacity: 0.3;
  }

  &__comments-item-header {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 14px;

      &--admin {
        svg rect {
          fill: $red;
        }
      }
    }
    .name {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    width: 460px;
    height: 54px;
    box-shadow: 0px 4px 12px rgba(185, 185, 185, 0.25);
    border-radius: 27px;

    &__item {
      width: 50%;
      text-align: center;
      font-weight: bold;
      font-size: 20px;
      color: $black;
      opacity: 0.9;
      cursor: pointer;

      &--active {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: $white;
        opacity: 1;
        background-color: $turquoise;
        border-radius: 27px;
        box-shadow: -2px 0px 4px rgba(33, 206, 180, 0.21);
      }
    }
  }
}
