@import "../../../styles/colors.scss";

.button {
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  border-radius: 2px;
  transition: all 0.2s ease-out;
  box-sizing: border-box;
  width: 100%;
  max-width: 350px;
  font-size: 20px;
  line-height: 25px;
  padding-bottom: 2px;
  text-align: center;

  &--turquoise {
    background-color: $turquoise;
    color: $white;

    &:hover {
      background-color: rgba(33, 206, 180, 0.5);
    }
  }

  &--yellow {
    background-color: $yellow;
    color: $black;

    &:hover {
      background-color: rgba(252, 241, 90, 0.5);
    }
  }

  &--text {
    color: $red;
    background-color: transparent;

    &:hover {
      color: rgba(230, 60, 36, 0.5);
    }
  }

  &--red {
    color: $white;
    background-color: $red;

    &:hover {
      background-color: rgba(230, 60, 36, 0.5);
    }
  }

  &--white {
    color: $turquoise;
    background-color: $white;

    &:hover {
      color: $white;
      background-color: transparent;
      border: 2px solid $white;
    }
  }

  &--transparent {
    background-color: transparent;
    border: 2px solid $turquoise;
    color: $turquoise;

    &:hover {
      color: $white;
      background-color: $turquoise;

      svg {
        fill: $white;

        & path {
          fill: $white;
        }
      }
    }
  }

  &--disabled {
    background-color: $light-grey;
    color: $white;
    cursor: default;

    &:hover,
    &:active {
      border: none;
      color: $white;
      background-color: $light-grey;
    }
  }

  &--loading {
    background-color: $white;
    border: 1px solid $turquoise;
    cursor: default;
    padding-bottom: 0;

    &:hover {
      background-color: $white;
      border: 1px solid $turquoise;
    }
  }

  &__icon {
    flex-shrink: 0;
    margin-right: 15px;
    line-height: 0;

    svg {
      line-height: 0;
      & path {
        fill: turquoise;
      }
    }
  }

  &__loader {
    line-height: 0;

    svg {
      animation: buttonLoading 3s linear infinite;
      fill: $turquoise;
    }
  }
}

@keyframes buttonLoading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
