@import "../../../styles/colors.scss";

.iconLink-component {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 20px;
  text-decoration: none;
  :active {
    text-decoration: none;
  }
  :hover {
    text-decoration: underline;
  }
  &__icon-wrapper {
    margin: 0;
    max-width: 30px;
    max-height: 30px;
    margin-right: 15px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 1px;
    font-weight: bold;
  }
}
