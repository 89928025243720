@import "../../../../../styles/colors";

.input-label {
  background-color: $pale-blue;
  padding: 17px 25px 15px;
  width: 360px;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 14px;
  color: $black;
  line-height: 22px;
  font-weight: 500;

  span {
    color: $red;
  }

  &--focused {
    background-color: $turquoise;
    color: $white;

    span {
      color: $black;
    }
  }
}
